import { getStrapiImageUrl } from "../../lib/get-strapi-image-url";

export function HeaderImage({
	url,
	alternativeText,
}: {
	url: string;
	alternativeText: string;
}) {
	return (
		<img
			src={getStrapiImageUrl(url)}
			alt={alternativeText}
			className="w-full h-full"
		/>
	);
}
