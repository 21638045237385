import { NavigationLinks } from "./navigation-links";
import { NavigationMenu } from "./navigation-menu";
import { useTranslation } from "react-i18next";
import type { NavLinkType } from "../../../types/main-page-types";
import { StrapiReturn } from "../../../types/strapi-return";
import { useMediaQuery } from "../../../hooks/use-media-query";

export function Navigation({ data }: { data?: StrapiReturn<NavLinkType[]> }) {
	const { i18n } = useTranslation();
	const { isMobile } = useMediaQuery();

	if (!data) {
		return null;
	}

	data.forEach((l) => {
		const { textPL, textEN } = l.attributes;

		const navLinks: { [key: number]: string } = {};

		navLinks[l.id] = textPL;

		i18n.addResourceBundle("pl", "translation", { navLinks }, true, true);

		navLinks[l.id] = textEN;

		i18n.addResourceBundle("en", "translation", { navLinks }, true, true);
	});

	return (
		<div className="text-[#002969] font-medium flex-1">
			{isMobile ? (
				<div className="text-white flex items-center">
					<NavigationMenu data={data} />
				</div>
			) : (
				<nav className="flex flex-wrap gap-8">
					<NavigationLinks data={data} />
				</nav>
			)}
		</div>
	);
}
