import i18n from "i18next";
import { create } from "zustand";

type LanguageType = "en" | "pl";

interface LanguageStore {
	lang: LanguageType;
	setLang: (lang: LanguageType) => void;
}

function getInitialLang(): LanguageType {
	if (localStorage.getItem("lang") === "en") {
		return "en";
	}

	return "pl";
}

export const useLanguageStore = create<LanguageStore>((set) => ({
	lang: getInitialLang(),
	setLang: (lang) => {
		localStorage.setItem("lang", lang);
		i18n.changeLanguage(lang);
		set({ lang });
	},
}));
