import moment from "moment";
import { NavLink } from "react-router-dom";

export function Footer() {
	return (
		<footer className="h-28 md:h-40 w-full max-w-[1200px] mx-auto px-[15px] relative">
			<div className=" w-full h-px bg-gray-300" />

			<div className="relative flex items-center h-full">
				<div className="flex gap-1 text-xs font-bold">
					<NavLink to="/" className="text-blue-600">
						mlecznebary.pl
					</NavLink>
					<span className="opacity-50">&copy; {moment().format("YYYY")}</span>
				</div>

				<div className="absolute top-1/2 left-full md:left-1/2 -translate-y-1/2 -translate-x-full md:-translate-x-1/2">
					<a
						href="#start"
						className="block bg-gray-400 hover:opacity-90 transition-opacity w-10 h-10 rounded-full"
					>
						<svg
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							stroke="#ffffff"
							className=""
						>
							<g strokeLinecap="round" strokeLinejoin="round" />
							<g>
								<path
									d="M12 6V18M12 6L7 11M12 6L17 11"
									stroke="#fff"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</g>
						</svg>
					</a>
				</div>
			</div>
		</footer>
	);
}
