import { NavLink } from "react-router-dom";
import { getStrapiImageUrl } from "../../lib/get-strapi-image-url";
import type { NewType } from "../../types/main-page-types";
import moment from "moment";
import { useTranslation } from "react-i18next";

export function New({
	data,
}: {
	data: {
		attributes: NewType;
		id: number;
	};
}) {
	const { t } = useTranslation();

	const { image, date } = data.attributes;
	const { url, alternativeText } = image.data.attributes;

	return (
		<NavLink
			to={`/aktualnosci/${data.id}`}
			className="flex flex-col w-full max-w-72 rounded overflow-hidden shadow shadow-gray-400 bg-white"
		>
			<div className="w-full h-0 pb-[65%] relative">
				<img
					src={getStrapiImageUrl(url)}
					alt={alternativeText}
					className="absolute top-0 left-0 w-full h-full object-cover"
				/>
			</div>

			<div className="flex flex-col gap-4 p-4 flex-1">
				<div className="opacity-50 text-sm">
					<time dateTime={date.toString()}>
						{moment(date).format("YYYY-MM-DD")}
					</time>
				</div>

				<p className="text-[#002969] font-semibold flex-1">
					{t(`news.${data.id}.title`)}
				</p>

				<div className="text-sm text-red-600">
					<span>{t("news.read")}</span>
				</div>
			</div>
		</NavLink>
	);
}
