import { getStrapiImageUrl } from "../../lib/get-strapi-image-url";
import type { BarType } from "../../types/main-page-types";

export function Bar({ href, image }: BarType) {
	const { url, alternativeText } = image.data.attributes;

	return (
		<div className="w-[300px] md:w-[220px] h-[96px]">
			<a
				href={href}
				className="inline-block w-full h-full relative rounded-lg bg-white group shadow-[0_6px_12px_0_#00000029] overflow-hidden"
			>
				<div className="absolute top-0 left-0 w-full h-full border-4 border-[#2F5DA5] rounded-lg transition-opacity opacity-0 group-hover:opacity-100 z-10" />

				<img
					src={getStrapiImageUrl(url)}
					alt={alternativeText}
					className="absolute top-0 left-0 w-full h-full p-1 object-contain"
				/>
			</a>
		</div>
	);
}
