import { useState } from "react";
import { NavigationLinks } from "./navigation-links";
import type { NavLinkType } from "../../../types/main-page-types";

export function NavigationMenu({
	data,
}: {
	data: {
		attributes: NavLinkType;
		id: number;
	}[];
}) {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	function onMenuClick() {
		const body = document.querySelector("body");

		if (!body) {
			return;
		}

		if (isMenuOpen) {
			body.classList.remove("overflow-hidden");
		} else {
			body.classList.add("overflow-hidden");
		}

		setIsMenuOpen((prev) => !prev);
	}

	return (
		<>
			<button className="relative z-50" onClick={onMenuClick}>
				<div
					className={`relative w-6 h-6 [&>*]:h-0.5 [&>*]:w-full [&>*]:absolute [&>*]:left-0 [&>*]:rounded [&>*]:transition-transform ${
						isMenuOpen ? "[&>*]:bg-white " : "[&>*]:bg-[#002969] "
					}`}
				>
					<div
						className={`top-0 ${
							isMenuOpen ? "rotate-45 top-1/2 -translate-y-1/2" : ""
						}`}
					/>
					<div
						className={`top-1/2 -translate-y-1/2 ${
							isMenuOpen ? "scale-0" : ""
						}`}
					/>
					<div
						className={`bottom-0 ${
							isMenuOpen ? "-rotate-45 top-1/2 -translate-y-1/2" : ""
						}`}
					/>
				</div>
			</button>

			<div
				className={`fixed ${
					isMenuOpen ? "left-0" : "-left-[100%]"
				} top-0 w-[75%] max-w-60 h-screen z-40 transition-all`}
			>
				<div
					className="bg-black opacity-85 absolute left-0 top-0 
						w-full h-full shadow-md shadow-black z-20"
				/>

				<div
					className="absolute left-0 top-0 w-screen h-screen z-10"
					onClick={onMenuClick}
				/>

				<nav className="mt-20 px-2 relative z-30 [&>*]:mb-2 [&>*:last]:mb-0">
					<NavigationLinks data={data} closeMenu={onMenuClick} />
				</nav>
			</div>
		</>
	);
}
