import { useTranslation } from "react-i18next";

export function Error() {
	const { t } = useTranslation();

	return (
		<div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-white z-[10000]">
			<div className="flex flex-col items-center">
				<h1 className="text-4xl font-bold">{t("error.title")}</h1>
				<button
					onClick={() => window.location.reload()}
					className="mt-4 px-4 py-2 bg-black text-white rounded-md"
				>
					{t("error.reload")}
				</button>
			</div>
		</div>
	);
}
