import { Error } from "./components/error/error";
import { useAppErrorStore } from "./hooks/use-app-error-store";
import { Route, Routes } from "react-router-dom";
import { NewsPage } from "./pages/news-page/news-page";
import { MainPage } from "./pages/main-page/main-page";
import { NewPage } from "./pages/news-page/new-page";
import { NotFoundPage } from "./pages/not-found-page/not-found-page";

export default function App() {
	const { isError } = useAppErrorStore();

	return (
		<div
			id="start"
			className={`font-['Asap'] overflow-hidden ${
				isError ? "h-screen overflow-hidden" : ""
			}`}
		>
			{isError && <Error />}

			<Routes>
				<Route path="" element={<MainPage />} />
				<Route path="/aktualnosci" element={<NewsPage />} />
				<Route path="/aktualnosci/:id?" element={<NewPage />} />
				<Route path="/*" element={<NotFoundPage />} />
			</Routes>
		</div>
	);
}
