import axios from "axios";
import { useState, useEffect } from "react";
import { useAppErrorStore } from "./use-app-error-store";

export function useFetch<T>(uri: string) {
	const [data, setData] = useState<T>();
	const { setError } = useAppErrorStore();

	useEffect(() => {
		async function fetchBars() {
			try {
				const res = await axios.get(uri);
				const data = res.data as T;
				setData(data);
			} catch (e: unknown) {
				setError();
			}
		}
		fetchBars();
	}, [setError, uri]);

	return { data };
}
