import { useLanguageStore } from "../../hooks/use-language-store";

export function LanguageToggle() {
	const { lang, setLang } = useLanguageStore();

	function onLangChange() {
		if (lang === "pl") {
			setLang("en");
		}

		if (lang === "en") {
			setLang("pl");
		}
	}

	return (
		<button className="w-6 h-6 relative" onClick={onLangChange}>
			{lang === "pl" && (
				<img
					src="/theme/lang/lang-en.jpeg"
					alt="english"
					className="absolute top-0 left-0 w-full h-full object-cover rounded-full"
				/>
			)}
			{lang === "en" && (
				<img
					src="/theme/lang/lang-pl.jpeg"
					alt="polski"
					className="absolute top-0 left-0 w-full h-full object-cover rounded-full"
				/>
			)}
		</button>
	);
}
