import { useTranslation } from "react-i18next";
import { useStrapiFetch } from "../../hooks/use-strapi-fetch";
import type {
	HeaderType,
	NavLinkType,
	NewType,
} from "../../types/main-page-types";
import { New } from "../../components/news/new";
import { Header } from "../../components/header/header";
import { Footer } from "../../components/footer/footer";
import { Loader } from "../../components/loader/loader";

export function NewsPage() {
	const { t, i18n } = useTranslation();

	const { data: headerData } = useStrapiFetch<HeaderType>(
		"/api/header?populate=image"
	);
	const { data: navLinksData } = useStrapiFetch<NavLinkType[]>(
		"/api/nav-links?sort=order:asc&sort=createdAt:asc"
	);
	const { data: newsData } = useStrapiFetch<NewType[]>(
		"/api/news?populate=image&sort=date:desc"
	);

	if (!headerData || !navLinksData || !newsData) {
		return <Loader />;
	}

	newsData.forEach((n) => {
		const { titlePL, titleEN } = n.attributes;

		const news: { [key: number]: { title: string } } = {};

		news[n.id] = { title: titlePL };

		i18n.addResourceBundle("pl", "translation", { news }, true, true);

		news[n.id] = { title: titleEN };

		i18n.addResourceBundle("en", "translation", { news }, true, true);
	});

	return (
		<>
			<Header
				data={headerData}
				navLinksData={navLinksData}
				title={t("news.title")}
			/>

			<main>
				<div className="relative w-full max-w-[1200px] mx-auto px-[15px] py-7 md:py-9">
					<div className="flex flex-wrap justify-center gap-4 md:gap-8">
						{newsData.map((n) => (
							<New key={`aktualnosci-${n.id}`} data={n} />
						))}
					</div>
				</div>
			</main>

			<Footer />
		</>
	);
}
