import { useTranslation } from "react-i18next";
import { useStrapiFetch } from "../../hooks/use-strapi-fetch";
import type {
	HeaderType,
	NavLinkType,
	NewType,
} from "../../types/main-page-types";
import { New } from "../../components/news/new";
import { NavLink, useParams } from "react-router-dom";
import { Markdown } from "../../components/common/markdown";
import moment from "moment";
import { Header } from "../../components/header/header";
import { Footer } from "../../components/footer/footer";
import { Loader } from "../../components/loader/loader";

export function NewPage() {
	const { t, i18n } = useTranslation();

	const { id } = useParams();

	const { data: headerData } = useStrapiFetch<HeaderType>(
		"/api/header?populate=image"
	);
	const { data: navLinksData } = useStrapiFetch<NavLinkType[]>(
		"/api/nav-links?sort=order:asc&sort=createdAt:asc"
	);
	const { data: newData } = useStrapiFetch<NewType>(`/api/news/${id}`);
	const { data: latestNewsData } = useStrapiFetch<NewType[]>(
		`/api/news?populate=image&sort=date:desc&${
			id ? `filters[id][$ne]=${id}&` : ""
		}pagination[pageSize]=3`
	);

	if (!headerData || !navLinksData || !newData || !latestNewsData) {
		return <Loader />;
	}

	const { titlePL, titleEN, bodyEN, bodyPL, date } = newData.attributes;

	const news: { [key: number]: { title: string; body: string } } = {};

	news[newData.id] = { title: titlePL, body: bodyPL };

	i18n.addResourceBundle("pl", "translation", { news }, true, true);

	news[newData.id] = { title: titleEN, body: bodyEN };

	i18n.addResourceBundle("en", "translation", { news }, true, true);

	latestNewsData.forEach((n) => {
		const { titlePL, titleEN } = n.attributes;

		const news: { [key: number]: { title: string } } = {};

		news[n.id] = { title: titlePL };

		i18n.addResourceBundle("pl", "translation", { news }, true, true);

		news[n.id] = { title: titleEN };

		i18n.addResourceBundle("en", "translation", { news }, true, true);
	});

	return (
		<>
			<Header
				data={headerData}
				navLinksData={navLinksData}
				title={t(`news.${newData.id}.title`)}
			/>

			<main>
				<div className="relative w-full max-w-[1200px] mx-auto px-[15px] py-7 md:py-9">
					<div className="flex flex-col md:flex-row items-start gap-6 md:gap-9">
						<div className="flex-1">
							<div className="opacity-50 text-sm">
								<time dateTime={date.toString()}>
									{moment(date).format("YYYY-MM-DD")}
								</time>
							</div>

							<Markdown className="mt-4">
								{t(`news.${newData.id}.body`)}
							</Markdown>
						</div>

						<div className="flex flex-row md:flex-col flex-wrap justify-center items-start gap-4 md:gap-6">
							{latestNewsData.map((n) => (
								<New key={`aktualnosci-${n.id}`} data={n} />
							))}
						</div>
					</div>

					<div className="text-red-600 hover:opacity-90 mt-9">
						<NavLink to="/aktualnosci">{t("news.goBack")}</NavLink>
					</div>
				</div>
			</main>

			<Footer />
		</>
	);
}
