import { Navigation } from "./navigation/navigation";
import { LanguageToggle } from "./language-toggle";
import type { HeaderType, NavLinkType } from "../../types/main-page-types";
import { HeaderImage } from "./header-image";
import { useTranslation } from "react-i18next";
import type { StrapiReturn } from "../../types/strapi-return";

export function Header({
	data,
	navLinksData,
	title,
}: {
	data?: StrapiReturn<HeaderType>;
	navLinksData?: StrapiReturn<NavLinkType[]>;
	title?: string;
}) {
	const { t, i18n } = useTranslation();

	if (!data) {
		return null;
	}

	const { image, href, textPL, textEN, textColor, bgColor } = data.attributes;
	const imageData = image.data.attributes;

	i18n.addResourceBundle(
		"pl",
		"translation",
		{ header: { button: textPL } },
		true,
		true
	);

	i18n.addResourceBundle(
		"en",
		"translation",
		{ header: { button: textEN } },
		true,
		true
	);

	return (
		<>
			<header className="w-full max-w-[1200px] mx-auto px-[15px] py-7 md:py-9 z-50 relative">
				<div className="flex gap-10 items-center">
					<Navigation data={navLinksData} />

					<LanguageToggle />
				</div>
			</header>

			<HeaderImage {...imageData} />

			<div className="flex justify-center mt-2 md:mt-0">
				<a
					href={href}
					className="px-8 py-4 transition-opacity hover:opacity-90 text-2xl font-semibold text-white shadow-[0_6px_12px_0_#00000029] rounded-full block"
					style={{ backgroundColor: bgColor, color: textColor }}
				>
					{t("header.button")}
				</a>
			</div>

			{!!title && (
				<div className="w-full max-w-[1200px] mx-auto px-[15px] pt-7 md:pt-9 relative">
					<h1 className="font-semibold text-[#002969] text-xl sm:text-2xl md:text-3xl text-center">
						{title}
					</h1>
				</div>
			)}
		</>
	);
}
