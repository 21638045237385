import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import { Loader } from "./components/loader/loader";

import "./lib/i18n";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Suspense fallback={<Loader />}>
				<App />
			</Suspense>
		</BrowserRouter>
	</React.StrictMode>
);
