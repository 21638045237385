import { News } from "../../components/news/news";
import { Map } from "../../components/map/map";
import { Career } from "../../components/career/career";
import { Bars } from "../../components/bars/bars";
import { Header } from "../../components/header/header";
import { useStrapiFetch } from "../../hooks/use-strapi-fetch";
import { useFetch } from "../../hooks/use-fetch";
import type {
	BarType,
	BrandType,
	CareerType,
	HeaderType,
	NavLinkType,
	NewType,
} from "../../types/main-page-types";
import { Loader } from "../../components/loader/loader";
import { Footer } from "../../components/footer/footer";

export function MainPage() {
	const { data: headerData } = useStrapiFetch<HeaderType>(
		"/api/header?populate=image"
	);
	const { data: navLinksData } = useStrapiFetch<NavLinkType[]>(
		"/api/nav-links?sort=order:asc&sort=createdAt:asc"
	);
	const { data: barsData } = useStrapiFetch<BarType[]>(
		"/api/bars?populate=image&sort=order:asc&sort=createdAt:asc"
	);
	const { data: newsData } = useStrapiFetch<NewType[]>(
		"/api/news?populate=image&sort=date:desc&pagination[pageSize]=4"
	);
	const { data: mapData } = useFetch<BrandType[]>(
		process.env.REACT_APP_BRANDS_API_URL || ""
	);
	const { data: careerData } = useStrapiFetch<CareerType>("/api/career");

	if (
		!headerData ||
		!navLinksData ||
		!barsData ||
		!newsData ||
		!mapData ||
		!careerData
	) {
		return <Loader />;
	}

	return (
		<>
			<Header data={headerData} navLinksData={navLinksData} />

			<main>
				<Bars data={barsData} />

				<News data={newsData} />

				<Map data={mapData} />

				<Career data={careerData} />
			</main>

			<Footer />
		</>
	);
}
