import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import leaflet from "leaflet";
import "leaflet/dist/leaflet.css";
import { getBrandLogoUrl } from "../../lib/get-brand-logo-url";
import type { BrandType } from "../../types/main-page-types";

function getIcon(src: string): leaflet.Icon {
	return new leaflet.Icon({
		iconUrl: src,
		iconRetinaUrl: src,
		iconSize: new leaflet.Point(50, 50),
		className: "object-contain",
	});
}

function calcCenter(brands: BrandType[]): [number, number] {
	let mapCenter: [number, number] = [0, 0];
	let brandsCount = 0;

	for (const brand of brands) {
		if (!brand.active) {
			continue;
		}

		let brandCenter = [0, 0];
		let placesCount = 0;

		for (const bar of brand.places) {
			if (!bar.active || !bar.config.geo) {
				continue;
			}

			const { lat, lon } = bar.config.geo;
			brandCenter = [brandCenter[0] + lat, brandCenter[1] + lon];
			placesCount++;
		}

		brandCenter = [brandCenter[0] / placesCount, brandCenter[1] / placesCount];

		mapCenter = [mapCenter[0] + brandCenter[0], mapCenter[1] + brandCenter[1]];
		brandsCount++;
	}

	return [mapCenter[0] / brandsCount, mapCenter[1] / brandsCount];
}

export function Map({ data }: { data?: BrandType[] }) {
	if (!data) {
		return null;
	}

	return (
		<div id="mapa">
			<MapContainer
				center={calcCenter(data)}
				zoom={6}
				scrollWheelZoom={false}
				className="w-screen min-h-[50vh] h-80"
			>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				{data.map(
					(brand: any) =>
						brand.active &&
						brand.places.map(
							(bar: any) =>
								!!bar.config.geo &&
								bar.active && (
									<Marker
										key={`${brand.id}-${bar.id}`}
										icon={getIcon(getBrandLogoUrl(bar.logo))}
										position={[bar.config.geo.lat, bar.config.geo.lon]}
									>
										<Popup>
											{brand.name}
											<br />
											{`${bar.prefStreet} ${bar.street} ${bar.streetNumber}, ${bar.city} ${bar.postCode}`}
										</Popup>
									</Marker>
								)
						)
				)}
			</MapContainer>
		</div>
	);
}
